// @ts-ignore
import libWorker from "worker-loader?inline=no-fallback!./worker.ts";

export default class FormXSession {
    worker: Worker | null | undefined = undefined;
    
    listener? : (message: any) => void;

    async initialize(model: string) {
        const response = await fetch(model);
        const arrayBuffer = await response.arrayBuffer();
        const uint8Array = new Uint8Array(arrayBuffer);

        const worker = new libWorker();
        this.worker = worker;

        worker.onmessage = (message: any) =>{
            this.listener?.(message);
        }

        return await new Promise((resolve) => {
            this.listener = (message: any) => {
                resolve(message);
                this.listener = undefined;
            }

            worker.postMessage({
                type: "loadModel",
                data: {
                    model: uint8Array,
                    baseUrl: window.location.origin.toString()
                }
            });       
        });     
    }

    async detect(inputImage: ImageData, inputWidth: number, inputHeight: number) {
        const worker = this.worker;
        if (!worker) {
            return;
        }

        return new Promise((resolve) => {
            this.listener = (message: any) => {
                resolve(message);
                this.listener = undefined;
            }

            worker.postMessage({
                type: "detect",
                data: {
                    inputImage,
                    inputWidth,
                    inputHeight
                }
            });
        });
    }
}

