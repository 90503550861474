import FormXDocumentRegion from "./FormXDocumentRegion";
import FormXSession from "./FormXSession";


export default class FormXDocumentDetector {

    session: FormXSession;

    static MODEL_INPUT_WIDTH = 640;
    static MODEL_INPUT_HEIGHT = 640;

    constructor(session: FormXSession) {
        this.session = session;
    }

    async detect(imageData: ImageData, 
        inputWidth: number = FormXDocumentDetector.MODEL_INPUT_WIDTH, 
        inputHeight: number = FormXDocumentDetector.MODEL_INPUT_HEIGHT) {
        if (!this.session) {
            return;
        }
        const session = this.session;

        const response: any = await session.detect(imageData, inputWidth, inputHeight);

        return FormXDocumentRegion.createFromJsonObject(response.data.data.region);
    }
}