import { createContext, useContext, useCallback, useState, useMemo } from "react";
import FormXSession from "./FormXSession";
import FormXDocumentDetector from "./FormXDocumentDetector";
import FormXDocumentRegion from "./FormXDocumentRegion";
import FormXRect from "./FormXRect";

async function scaleImage(dataUrl: string, width: number, height: number): Promise<ImageData> {
    return new Promise(resolve => {
        const img = document.createElement("img");
        img.onload = () => {       
            const canvas = document.createElement("canvas");
            canvas.width = width
            canvas.height = height;
            
            const ctx = canvas.getContext("2d");
            if (ctx === null) {
                throw new Error("Failed to get canvas context");
            }
        
            ctx.drawImage(img, 0, 0, width, height);
            resolve(ctx.getImageData(0, 0, width, height));
        }
        img.src = dataUrl;
    })
}

async function cropImage(dataUrl: string, bounding: FormXRect): Promise<string> {
    return new Promise(resolve => {
        const img = document.createElement("img");
        img.onload = () => {
            const canvas = document.createElement("canvas");
            const sourceRegion = bounding.scaled(img.width, img.height);
            canvas.width = sourceRegion.width;
            canvas.height = sourceRegion.height;
            const ctx = canvas.getContext("2d");
            if (ctx === null) {
                throw new Error("Failed to get canvas context");
            }
            ctx.drawImage(img, sourceRegion.x, sourceRegion.y, sourceRegion.width, sourceRegion.height, 0, 0, sourceRegion.width, sourceRegion.height);
            resolve(canvas.toDataURL("image/jpeg"));
        }
        img.src = dataUrl;
    });
}

function useMakeContext() {
    const [session] = useState(new FormXSession());

    const [loadingSpinnerVisible, setLoadingSpinnerVisible] = useState(false);
   
    const [currentPage, _setCurrentPage] = useState("home-view");
    const [takenPhoto, setTakenPhoto] = useState<string>("");

    const setCurrentPage = useCallback((page: string) => {
        setLoadingSpinnerVisible(false);
        _setCurrentPage(page);
    }, []);

    // Take photo using the native app camera
    const pickPhoto = useCallback(() => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        // input.capture = "environment";
        input.style.display = "none";
        input.onchange = async (event) => {
            const file = (event.target as HTMLInputElement).files?.[0] ?? null;
            if (!file) {
                return;
            }
            const reader = new FileReader();
            setLoadingSpinnerVisible(true);
            reader.onload = async (event) => {               
                const loadedPhoto = event.target?.result as string;
                const scaledImage = await scaleImage(loadedPhoto, FormXDocumentDetector.MODEL_INPUT_WIDTH, FormXDocumentDetector.MODEL_INPUT_HEIGHT);

                const detector = new FormXDocumentDetector(session)
                const region = await detector.detect(scaledImage) ?? FormXDocumentRegion.createFromRect(0,0,1.0,1.0);

                const boundingRect = region.boundingRect;

                const croppedImage = await cropImage(loadedPhoto, boundingRect);

                setTakenPhoto(croppedImage);
                setCurrentPage("photo-view");
                input.remove();
                setLoadingSpinnerVisible(false);
            }
            reader.readAsDataURL(file);
        }
        document.body.appendChild(input);
        input.click();
    }, []);


    return useMemo(() => ({
        loadingSpinnerVisible,
        setLoadingSpinnerVisible,
        currentPage,
        setCurrentPage,
        pickPhoto,
        takenPhoto,
        setTakenPhoto,
        session
    }), [
        loadingSpinnerVisible,
        currentPage,
        takenPhoto,
        session
    ]);
}

type MainContextValue = ReturnType<typeof useMakeContext>;

const MainContext = createContext<MainContextValue>(null as any);

interface Props {
    children: React.ReactNode;
}
 
export function useMainContext() {
    return useContext(MainContext);
}

export const MainContextProvider = (props: Props) => {
    const value = useMakeContext();
    return <MainContext.Provider {...props} value={value} />;
}
