import { useCallback } from "react";
import appConfig from "./appconfig.json";
import "./HomeView.css";

interface RowProps {
    name: string;
    formId: string;
    accessToken: string;
    onFormSelected: (formId: string, accessToken: string) => void;
}

function Row(props: RowProps) {
    const {
        name,
        formId,
        accessToken,
        onFormSelected
    } = props;

    const onClick = useCallback(() => {
        onFormSelected(formId, accessToken);
    }, []);

    return (
        <li className="list-group-item HomeView-Link" onClick={onClick}>
            {name}
        </li>
    )
}

interface Props {
    onFormSelected: (formId: string, accessToken: string) => void;
}

export default function HomeView(props: Props) {

    const {
        onFormSelected
    } = props;

    return (<div>
        <h1 className="p-2">FormX Mobile SDK Demo</h1>
        <hr className="my-4"></hr>
        <ul className="list-group">
            {
                appConfig.forms.map((form, index) => {
                    return <Row 
                        key={index}
                        name={form.name} 
                        formId={form.formId}
                        accessToken={form.accessToken}
                        onFormSelected={onFormSelected}/>
                })
            }
        </ul>
    </div>)
}