import FormXPoint from "./FormXPoint";
import FormXRect from "./FormXRect";

export default class FormXDocumentRegion {
    topLeft: FormXPoint = new FormXPoint(0, 0);
    topRight: FormXPoint = new FormXPoint(0, 0);
    bottomLeft: FormXPoint = new FormXPoint(0, 0);
    bottomRight: FormXPoint = new FormXPoint(0, 0);

    constructor(topLeft: FormXPoint, topRight: FormXPoint, 
        bottomRight: FormXPoint,
        bottomLeft: FormXPoint) {
        this.topLeft = topLeft;
        this.topRight = topRight;
        this.bottomLeft = bottomLeft;
        this.bottomRight = bottomRight;
    }

    static createFromRect(x: number, y: number, width: number, height: number) {
        const topLeft = new FormXPoint(x, y);
        const topRight = new FormXPoint(x + width, y);
        const bottomLeft = new FormXPoint(x, y + height);
        const bottomRight = new FormXPoint(x + width, y + height);
        return new FormXDocumentRegion(topLeft, topRight, bottomRight, bottomLeft);
    }

    static createFromJsonObject(object: any): FormXDocumentRegion | undefined {
        if (!object) {
            return undefined
        }
        const topLeft = new FormXPoint(object.topLeft.x, object.topLeft.y);
        const topRight = new FormXPoint(object.topRight.x, object.topRight.y);
        const bottomLeft = new FormXPoint(object.bottomLeft.x, object.bottomLeft.y);
        const bottomRight = new FormXPoint(object.bottomRight.x, object.bottomRight.y);
        return new FormXDocumentRegion(topLeft, topRight, bottomRight, bottomLeft);
    }

    get boundingRect() {
        return new FormXRect(this.topLeft.x, this.topLeft.y, 
            this.topRight.x - this.topLeft.x, 
            this.bottomLeft.y - this.topLeft.y);
    }

    toVertices() {
        return [this.topLeft, this.topRight, this.bottomRight, this.bottomLeft];
    }
}