import {useState, useCallback } from "react";
import "./App.css";
import CameraView from "./CameraView";
import PhotoView from "./PhotoView";
import HomeView from "./HomeView";
import { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import FormXAPIClient from "./FormXAPIClient";
import ResultView from "./ResultView";
import { FormXAPIExtractResponse } from "./FormXAPIExtractResult";
import { useMainContext } from "./MainContext";

function App() {
    const [modelReady, setModelReady] = useState(false);
    const [extractionResult, setExtractionResult]  = useState<FormXAPIExtractResponse|undefined>(undefined);
    const {
        session,
        loadingSpinnerVisible, currentPage, setCurrentPage,
        takenPhoto, setTakenPhoto} = useMainContext();

    useEffect(() => {
        const appHeight = () => {
            const doc = document.documentElement
            doc.style.setProperty("--app-height", `${window.innerHeight}px`)
        }
        window.addEventListener("resize", appHeight);
        appHeight();

        const initialize = async () => {
            return session.initialize("/model.ort");
        }

        initialize().then(() => {
            setModelReady(true);
        });
    }, []);

    const [apiClient, setApiClient] = useState<FormXAPIClient | undefined>();

    const onPhotoTaken = useCallback((photo: ImageData) => {
        const buffer = document.createElement("canvas");
        buffer.width = photo.width;
        buffer.height = photo.height;
        buffer.getContext("2d")?.putImageData(photo, 0, 0)

        setTakenPhoto(buffer.toDataURL("image/jpeg"));
        setCurrentPage("photo-view");
    }, []);

    const onFormSelected = useCallback(
        (formId: string, accessToken: string) => {
            setCurrentPage("camera-view");
            setApiClient(new FormXAPIClient(formId, accessToken));
        }, []);
 
    const onExtractButtonClicked = useCallback(
        async () => {
            if (!apiClient) {
                return;
            }
            const base64 = takenPhoto.split(";base64,")[1];
   
            const response = await apiClient.extract(base64);

            setExtractionResult(response);
            setCurrentPage("result-view");

        }, [takenPhoto, apiClient]);

    return (
        <div className="App container">
            {
                currentPage === "home-view" && (
                    <HomeView onFormSelected={onFormSelected}></HomeView>
                )
            }
            {
                currentPage === "photo-view" && takenPhoto !== "" && (
                    <>
                        <PhotoView 
                            takenPhoto={takenPhoto} 
                            onBackButtonClicked={() => setCurrentPage("camera-view")}
                            onExtractButtonClicked={onExtractButtonClicked}
                        />
                    </>
                )
            }
            {
                currentPage === "camera-view" && modelReady && (
                    <CameraView 
                        onPhotoTaken={onPhotoTaken}/>
                )
            }
            {
                currentPage === "result-view" && 
                    extractionResult !== undefined && (
                    <ResultView result={extractionResult}
                        onBackButtonClicked={() => setCurrentPage("camera-view")}
                    />
                )
            }
            {
                !modelReady && (
                    <div className="App-backdrop">
                        <div className="mx-auto mx-auto spinner-border text-light" role="status"></div>
                    </div>
                )
            }

            {
                loadingSpinnerVisible && (
                    <div className="App-backdrop">
                        <div className="mx-auto mx-auto spinner-border text-light" role="status"></div>
                    </div>
                )
            }
        </div>
    );
}

export default App;
