
export default class FormXPoint {
    x: number;
    y: number;
    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }

    distanceSq(point: FormXPoint) {
        return Math.pow(this.x - point.x, 2) + Math.pow(this.y - point.y, 2);
    }

    findClosest(points: FormXPoint[]) : FormXPoint {
        const distances = points.map(point => this.distanceSq(point));
        const min = Math.min.apply(null, distances);
        const index = distances.indexOf(min);
        return points[index];
    }
}