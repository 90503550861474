import BezierEasing from "bezier-easing"
import FormXDocumentRegion from "./FormXDocumentRegion";
import FormXPoint from "./FormXPoint";

function calcNumberValue(start: number | undefined, end: number | undefined, progressValue: number): number {
    const endValue = end ?? 0;
    if (start === undefined) {
        return endValue;
    }
    return start + (endValue - start) * progressValue;
}

function calcPointValue(start: FormXPoint | undefined, end: FormXPoint | undefined, progressValue: number): FormXPoint | undefined {
    if (start === undefined) {
        return end;
    }
    if (end === undefined) {
        return undefined;
    }
    return new FormXPoint(start.x + (end.x - start.x) * progressValue, start.y + (end.y - start.y) * progressValue);
}

function calcDocumentRegionValue(start: FormXDocumentRegion | undefined, end: FormXDocumentRegion | undefined, progressValue: number): FormXDocumentRegion | undefined {
    if (start === undefined) {
        return end;
    }
    if (end === undefined) {
        return undefined;
    }
    const topLeft = calcPointValue(start.topLeft, end?.topLeft, progressValue) ?? new FormXPoint(0, 0);
    const topRight = calcPointValue(start.topRight, end?.topRight, progressValue) ?? new FormXPoint(0, 0);
    const bottomLeft = calcPointValue(start.bottomLeft, end?.bottomLeft, progressValue) ?? new FormXPoint(0, 0);
    const bottomRight = calcPointValue(start.bottomRight, end?.bottomRight, progressValue) ?? new FormXPoint(0, 0);

    return new FormXDocumentRegion(topLeft, topRight, bottomRight, bottomLeft);
}

function calcValue(start: any, end: any, progressValue: number): any {
    if (start instanceof FormXDocumentRegion || end instanceof FormXDocumentRegion) {
        return calcDocumentRegionValue(start, end, progressValue);
    }
    return calcNumberValue(start, end, progressValue);
}

function equals(a: any, b: any): boolean {
    if (a instanceof FormXDocumentRegion || b instanceof FormXDocumentRegion) {
        return JSON.stringify(a) === JSON.stringify(b);
    }
    return a === b;
}

export default class FormXAnimation<Type> {
    endValue: Type | undefined = undefined;
    startValue: Type | undefined = undefined;
    startTime= 0;
    duration = 0;
    bezierEasing = BezierEasing(0.46,0.03,0.52,0.96);

    constructor(duration: number, bezierEasing?: BezierEasing.EasingFunction) {
        this.duration = duration;
        if (bezierEasing) {
            this.bezierEasing = bezierEasing;
        }
    }

    start(endValue?: Type, timestamp = -1) {
        if (equals(endValue, this.endValue)) {
            return;
        }
        this.startValue = this.endValue;
        this.startTime = timestamp < 0 ? new Date().getTime() : timestamp;
        this.endValue = endValue;
    }

    getCurrentValue(timestamp = -1): Type {
        const currentTime = timestamp < 0 ? new Date().getTime() : timestamp;
        let progressTime = (currentTime - this.startTime) / this.duration;
        progressTime = Math.min(progressTime, 1);
        const progressValue = this.bezierEasing(progressTime);
        return calcValue(this.startValue, this.endValue, progressValue) as Type;
    }
}