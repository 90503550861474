import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import FormXDocumentDetector from "./FormXDocumentDetector";
import FormXDocumentRegion from "./FormXDocumentRegion";
import Button from "react-bootstrap/Button";
import FormXLetterBox from "./FormXLetterbox";
import FormXStabilizer from "./FormXStabilizer";
import FormXAnimation from "./FormXAnimation";
import { useMainContext } from "./MainContext";

function iOS() {
    return [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod"
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

function getVideoConstraints() {
    if (iOS()) {
        return {
            audio: false,
            video: {
                facingMode: "environment",
            }
        };
    }
    return  {
        audio: false,
        video: {
            optional: [
                {minWidth: 320},
                {minWidth: 640},
                {minWidth: 1024},
                {minWidth: 1280},
                {minWidth: 1920},
                {minWidth: 2560},
            ]
        },
    }    
}

async function openVideo(video: any) {

    const constraints = getVideoConstraints();

    try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints as any);
        video.playsInline = true;
        video.srcObject = stream;
        video.onloadedmetadata = () => {
            video.play();
        };
        return stream;
    } catch (e) {
        //@FIXME handle denied access
        console.log(e);
    }
}

function copyVideoToFillCanvas(source: HTMLVideoElement,  buffer: HTMLCanvasElement, width: number, height: number) {
    buffer.width = width;
    buffer.height = height;
    const ctx = buffer.getContext("2d");
    if (!ctx) {
        throw new Error("Failed to get canvas context");
    }
    ctx.drawImage(source, 0, 0, width, height);
    return ctx.getImageData(0, 0, width, height);
}

function getStabilizerStateText(stabilizer: FormXStabilizer) {
    switch (stabilizer.state) {
    case FormXStabilizer.StabilizerState.DocumentFinding:
        return "Finding document";
    case FormXStabilizer.StabilizerState.WaitingForSteady:
        return "Waiting for steady";
    case FormXStabilizer.StabilizerState.ReadyToCapture:
        return "Ready to capture";
    }
}

interface Props {
    onPhotoTaken: (photo: ImageData) => void
}

const MODEL_INPUT_LENGTH = 640;

export default function CameraView(props: Props) {
    const {
        onPhotoTaken
    } = props;

    const mutableState = useRef<{
        cameraRunning: boolean,
        stream?: MediaStream,
        realTimeDetectionRunning: boolean,
        region: FormXDocumentRegion | undefined,
        opacityAnimation: FormXAnimation<number>,
        regionAnimation: FormXAnimation<FormXDocumentRegion>
    }>({
        cameraRunning: true,
        stream: undefined,
        realTimeDetectionRunning: false,
        region: undefined,
        opacityAnimation: new FormXAnimation<number>(500),
        regionAnimation: new FormXAnimation<FormXDocumentRegion>(500)
    });

    const {setLoadingSpinnerVisible, pickPhoto, session} = useMainContext();

    const videoRef = useRef<HTMLVideoElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [detector] = useState(new FormXDocumentDetector(session));
    const [stabilizer] = useState(new FormXStabilizer());

    const drawBuffer = useMemo(() => {
        const buffer = document.createElement("canvas");
        buffer.width = MODEL_INPUT_LENGTH;
        buffer.height = MODEL_INPUT_LENGTH;
        return buffer;
    }, []);

    const detect = useCallback(async () => {
        const video = videoRef.current;
        if (!video || !detector) {
            return;
        }

        const imageData = copyVideoToFillCanvas(video, drawBuffer, MODEL_INPUT_LENGTH, MODEL_INPUT_LENGTH);

        return detector.detect(imageData, MODEL_INPUT_LENGTH, MODEL_INPUT_LENGTH);
    }, []);

    const runRealTimeDetection = useCallback(async () => {
        if (mutableState.current.realTimeDetectionRunning) {
            return;
        }

        mutableState.current.realTimeDetectionRunning = true;
        const region = await detect();
        stabilizer.update(region);
        mutableState.current.region = stabilizer.smoothedRegion;
        
        if (stabilizer.smoothedRegion === undefined) {
            mutableState.current.opacityAnimation.start(0);
        } else {
            mutableState.current.opacityAnimation.start(0.3);
        }
        mutableState.current.regionAnimation.start(region);

        mutableState.current.realTimeDetectionRunning = false;
    }, []);

    const render = useCallback(() => {
        const canvas = canvasRef.current;
        const video = videoRef.current;
        if (!mutableState.current.cameraRunning || !canvas || !video ) {
            return;
        }
        const ctx = canvas.getContext("2d");
        if (!ctx) {
            return;
        }

        canvas.width = canvas.clientWidth;
        canvas.height = canvas.clientHeight;

        const width = canvas.width;
        const height = canvas.height;

        const letterbox = FormXLetterBox.create(
            video.videoWidth, video.videoHeight, width, height);

        ctx.drawImage(
            video, 0, 0, video.videoWidth, video.videoHeight,
            letterbox.offsetX, letterbox.offsetY, 
            letterbox.scaledWidth, letterbox.scaledHeight);
       
        runRealTimeDetection();

        const region = mutableState.current.regionAnimation.getCurrentValue();
        
        const dx = (x: number) => {
            return x * letterbox.scaledWidth + letterbox.offsetX;
        }

        const dy = (y: number) => {
            return y * letterbox.scaledHeight + letterbox.offsetY;
        }

        if (region) {
            const opacity = mutableState.current.opacityAnimation.getCurrentValue();
            ctx.beginPath();
            ctx.lineWidth = 2;
            ctx.fillStyle = `rgba(255, 0, 0, ${opacity})`;
            ctx.moveTo(
                dx(region.topLeft.x),
                dy(region.topLeft.y)
            );
            ctx.lineTo(
                dx(region.topRight.x),
                dy(region.topRight.y)
            );
            ctx.lineTo(
                dx(region.bottomRight.x),
                dy(region.bottomRight.y)
            );
            ctx.lineTo(
                dx(region.bottomLeft.x),
                dy(region.bottomLeft.y)
            );
            ctx.closePath();
            ctx.fill();
        }
        ctx.font = "48px Arial";
        const overlayMessage = getStabilizerStateText(stabilizer);
        const overlayMessageSize = ctx.measureText(overlayMessage);
        const overlayMessageHeight = overlayMessageSize.actualBoundingBoxAscent + overlayMessageSize.actualBoundingBoxDescent;
        ctx.fillStyle = "#FFFFFF7F";
        const margin = 12;
        ctx.fillRect((width - overlayMessageSize.width - margin* 2) / 2,
            (height - overlayMessageHeight * 3 - margin) / 2,
            overlayMessageSize.width + margin * 2, overlayMessageHeight + margin * 2
        )
        ctx.fillStyle = "#000";
        ctx.fillText(
            overlayMessage,
            (width - overlayMessageSize.width) / 2, 
            (height - overlayMessageHeight) / 2);

        if (stabilizer.state === FormXStabilizer.StabilizerState.ReadyToCapture) {
            // takePhoto();
        }

        requestAnimationFrame(render);
    }, []);

    const takePhoto = useCallback(async () => {
        const video = videoRef.current;
        if (!video || !detector) {
            return;
        }
        mutableState.current.cameraRunning = false;
        setLoadingSpinnerVisible(true)
        
        const width = video.videoWidth;
        const height = video.videoHeight;

        const capturedFullSizePhoto = copyVideoToFillCanvas(video, drawBuffer, width, height);
        const region = await detect() ?? FormXDocumentRegion.createFromRect(0,0,1.0,1.0);
        let boundingRect = region.boundingRect;

        const ctx = drawBuffer.getContext("2d");
        if (!ctx) {
            return;
        }

        drawBuffer.width = width;
        drawBuffer.height = height;
        ctx.putImageData(capturedFullSizePhoto, 0, 0);

        boundingRect = boundingRect.scaled(width, height);

        const croppedPhoto = ctx.getImageData(
            boundingRect.x, boundingRect.y, boundingRect.width, boundingRect.height);
        onPhotoTaken(croppedPhoto);
    }, []);

    useEffect(() => {
        openVideo(videoRef.current).then((stream?: MediaStream) => {
            mutableState.current.stream = stream;
            render();
        });
        return () => {
            mutableState.current.cameraRunning = false;
            const video = videoRef.current;
            if (video) {
                video.src ="";
                video.pause();
            }
            mutableState.current.stream?.getTracks().forEach((track) => {
                track.stop();
            });
        }
    }, []);
     
    return (
        <div className="d-flex flex-column h-100">
            <div className="flex-grow-1 p-1 position-relative">
                <div  className="top-0 bottom-0 start-0 end-0 position-absolute">
                    <canvas ref={canvasRef} className="w-100 h-100"></canvas>

                </div>
            </div>
            <video style={{display: "none"}} ref={videoRef} width="320" height="240"></video>
            <div className="row p-2">
                <div className="text-center">
                    <Button onClick={takePhoto} className="m-2">Take Photo</Button>
                    <Button onClick={pickPhoto} className="m-2">Pick Photo</Button>
                </div>
            </div>
        </div>
    )
}
