export default class FormXRect {
    x: number;
    y: number;
    width: number;
    height: number;
    constructor(x: number, y: number, width: number, height: number) {
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
    }

    scaled(scaleX: number, scaleY: number) {
        return new FormXRect(this.x * scaleX, this.y * scaleY, this.width * scaleX, this.height * scaleY);
    }
}