import FormXDocumentRegion from "./FormXDocumentRegion";
import FormXPoint from "./FormXPoint";

const STABILIZER_SLIDING_WINDOW_SIZE = 6;
const STABILIZER_STEADY_THRESHOLD = 0.14;
const STABILIZER_RESET_THRESHOLD = 3;

enum StabilizerState {
    DocumentFinding,
    WaitingForSteady,
    ReadyToCapture
}

export default class FormXStabilizer {
    static readonly StabilizerState = StabilizerState;

    smoothedRegion?: FormXDocumentRegion = undefined;

    slidingWindowSize: number = STABILIZER_SLIDING_WINDOW_SIZE;

    streadyThreshold: number = STABILIZER_STEADY_THRESHOLD;

    state: StabilizerState = StabilizerState.DocumentFinding;

    capturedRegions: FormXDocumentRegion[] = [];

    accDiff = 0;

    emptyCount = 0;

    constructor() {
        this.reset();
    }

    reset() {
        this.capturedRegions = [];
        this.smoothedRegion = undefined;
        this.accDiff = this.streadyThreshold + 1;
        this.state = StabilizerState.DocumentFinding;
    }

    update(region?: FormXDocumentRegion) {
        if (region === undefined) {
            this.emptyCount += 1;
            if (this.emptyCount > STABILIZER_RESET_THRESHOLD) {
                this.reset();
            }
            return;
        }

        this.emptyCount = 0;
        this.capturedRegions.push(region);

        this.capturedRegions = this.capturedRegions.slice(-this.slidingWindowSize);

        let accDiff = 0;

        const incAccDiff = (p1: FormXPoint, p2: FormXPoint) => {
            accDiff += p1.distanceSq(p2);
        }

        const avgPoints = (regions: FormXDocumentRegion[], 
            callback: (region: FormXDocumentRegion) => FormXPoint) => {
            const points = regions.map((region: FormXDocumentRegion) => callback(region));
            const pt = points.reduce(
                (a, b) => {
                    a.x = a.x + b.x;
                    a.y = a.y + b.y;
                    return a
                }, new FormXPoint(0,0));
            pt.x = pt.x / points.length;
            pt.y = pt.y / points.length;
            return pt;
        }

        for (let i = 1; i < this.capturedRegions.length - 1; i++) {
            const r1 = this.capturedRegions[i -1];
            const r2 = this.capturedRegions[i];

            incAccDiff(r1.topLeft, r2.topLeft);
            incAccDiff(r1.topRight, r2.topRight);
            incAccDiff(r1.bottomLeft, r2.bottomLeft);
            incAccDiff(r1.bottomRight, r2.bottomRight);
        }

        const topLeft = avgPoints(this.capturedRegions, (region) => region.topLeft);
        const topRight = avgPoints(this.capturedRegions, (region) => region.topRight);
        const bottomLeft = avgPoints(this.capturedRegions, (region) => region.bottomLeft);
        const bottomRight = avgPoints(this.capturedRegions, (region) => region.bottomRight);

        this.smoothedRegion = new FormXDocumentRegion(topLeft, topRight, bottomRight, bottomLeft);
        if (this.capturedRegions.length < (this.slidingWindowSize / 2)) {
            this.state = StabilizerState.DocumentFinding;
            return;
        }

        if (accDiff > this.streadyThreshold || this.capturedRegions.length < this.slidingWindowSize) {
            this.state = StabilizerState.WaitingForSteady;
            return;
        }

        this.state = StabilizerState.ReadyToCapture;
    }


}