import { FormXAPIExtractResponse } from "./FormXAPIExtractResult";

const DEFAULT_API_HOST = "https://worker.formextractorai.com";

export default class FormXAPIClient {
    
    formId: string

    accessToken: string

    apiHost = DEFAULT_API_HOST

    constructor(formId: string, accessToken: string, apiHost?: string) {
        this.formId = formId;
        this.accessToken = accessToken;
        if (apiHost) {
            this.apiHost = apiHost;
        }
    }
    
    async extract(base64Image: string): Promise<FormXAPIExtractResponse> {
        const url = `${this.apiHost}/extract`;

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "image/jpeg",
                "X-WORKER-FORM-ID": this.formId,
                "X-WORKER-TOKEN": this.accessToken,
                "X-WORKER-ENCODING": "base64"
            },
            body: base64Image
        });
        return await response.json();
    }
}