import { useCallback } from "react";
import Button from "react-bootstrap/Button";
import "./PhotoView.css";
import { useMainContext } from "./MainContext";

interface Props {
    takenPhoto: string,
    onBackButtonClicked: () => void,
    onExtractButtonClicked: () => void
}

export default function PhotoView(props: Props) {
    const {
        takenPhoto,
        onBackButtonClicked,
        onExtractButtonClicked
    } = props;

    const {setLoadingSpinnerVisible} = useMainContext();

    const extract = useCallback(() => {
        setLoadingSpinnerVisible(true);
        onExtractButtonClicked();
    }, [onExtractButtonClicked]);

    return (
        <>
            <div className="d-flex flex-column h-100">
                <div className="flex-grow-1 p-2 position-relative">
                    <div className="position-absolute top-0 bottom-0 start-0 end-0">
                        <img className="w-100 h-100 PhotoView-Photo" src={takenPhoto}/>
                    </div>
                </div>
                <div className="row p-2">
                    <div className="text-center">
                        <Button onClick={onBackButtonClicked} className="m-2">Back</Button>
                        <Button className="m-2" onClick={extract}>Extract</Button>
                    </div>
                </div>
            </div>
        </>
    );

}