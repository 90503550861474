export default class FormXLetterBox {
    sourceWidth = 0;
    sourceHeight = 0;
    targetWidth = 0;
    targetHeight = 0;
    scale = 0;
    offsetX = 0;
    offsetY = 0;
    scaledWidth = 0;
    scaledHeight = 0;

    constructor(sourceWidth: number, sourceHeight: number, targetWidth: number, targetHeight: number, scale: number, offsetX: number, offsetY: number, scaledWidth: number, scaledHeight: number) {
        this.sourceWidth = sourceWidth;
        this.sourceHeight = sourceHeight;
        this.targetWidth = targetWidth;
        this.targetHeight = targetHeight;
        this.scale = scale;
        this.offsetX = offsetX;
        this.offsetY = offsetY;
        this.scaledWidth = scaledWidth;
        this.scaledHeight = scaledHeight;
    }

    static create(sourceWidth: number, sourceHeight: number, targetWidth: number, targetHeight: number) {
        const scale = Math.min(targetWidth / sourceWidth, targetHeight / sourceHeight);
        const scaledWidth = sourceWidth * scale;
        const scaledHeight = sourceHeight * scale;
        const offsetX = (targetWidth - scaledWidth) / 2;
        const offsetY = (targetHeight - scaledHeight) / 2;

        return new FormXLetterBox(sourceWidth, sourceHeight, targetWidth, targetHeight, scale, offsetX, offsetY, scaledWidth, scaledHeight);
    }
}