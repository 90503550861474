import Button from "react-bootstrap/Button";
import { FormXAPIExtractResponse, FormXAutoExtractionItem, FormXAutoExtractionPurchaseItem } from "./FormXAPIExtractResult";


interface SingleValueRowProps {
    name: string,
    value: string | number
}

function SingleValueRow(props: SingleValueRowProps) {
    const {
        name,
        value
    } = props;

    return (
        <div className="form-group row  align-items-center">
            <label className="col-sm-2 p-2">
                {name}
            </label>
            <span className="col-sm-10 align-middle">
                <input type="text" readOnly={true} className="w-100" value={value}/>
            </span>
        </div>
    )
}

interface RowProps {
    item: FormXAutoExtractionItem;
}

function Row(props: RowProps) {
    const {
        name,
        value
    } = props.item;

    if (Array.isArray(value)) {
        const purhcaseItems = value as FormXAutoExtractionPurchaseItem[];

        return (
            <>
                {purhcaseItems.map((item, index) => (
                    <form className="p-2" key={index}>
                        <div className="col-sm-12"><p><b>{name}</b></p></div>

                        { item.amount !== undefined && (
                            <SingleValueRow name="Amount" value={item.amount}/>
                        )}

                        { item.discount !== undefined && (
                            <SingleValueRow name="Discount" value={item.discount}/>
                        )}

                        { item.name !== undefined && (
                            <SingleValueRow name="Name" value={item.name}/>
                        )}

                        { item.unitPrice !== undefined && (
                            <SingleValueRow name="Unit Price" value={item.unitPrice}/>
                        )}

                        { item.sku !== undefined && (
                            <SingleValueRow name="Sku" value={item.sku}/>                          
                        )}

                        { item.quantity !== undefined && (
                            <SingleValueRow name="Qty" value={item.quantity}/>                          
                        )}

                    </form>
                ))
                }
            </>
        )
    }

    const strIntValue = value as string | number;

    return (
        <>
            {
                (strIntValue) && (
                    <form className="p-2">
                        <SingleValueRow name={name} value={strIntValue}/>
                    </form>
                )
            }
        </>
    )
}

interface Props {
    result: FormXAPIExtractResponse,
    onBackButtonClicked: () => void
}

export default function ResultView(props: Props) {
    const {
        result,
        onBackButtonClicked
    } = props;

    const {
        auto_extraction_items
    } = result;
    
    return (
        <>
            {
                auto_extraction_items.map((item: FormXAutoExtractionItem, index: number) => {
                    return (<Row key={index} item={item}></Row>)
                })
            }

            <div className="row p-2">
                <div className="text-center">
                    <Button onClick={onBackButtonClicked} className="m-2">Back</Button>
                </div>
            </div>
        </>
    )
}